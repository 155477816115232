// Background network
.neural-network {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: 500ms all 250ms;
  //transform: scale(0);
  opacity: 0;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    top: -50%;
    left: -50%;
    transform: scale(.5);
    transform-origin: center center;
    fill: #fafafa;
  }

  &.visible {
    opacity: 1;
    transform: scale(1);
  }
}