.header {

  &.animated {
    position: absolute;
    z-index: 1000;
    top: 0;
    transition: 2s all 1s;
    z-index: 2;
    opacity: 0;
  }

  width: 100%;
  padding: 20px 30px;

  .logo {
    max-width: 960px;
    margin: 4vh auto 0;
    transition: all 250ms;
    display: inline-block;
    padding-left: 2em;

    img {
      max-width: 225px;
      @include breakpoint(phones) {
        max-width: 180px;
        padding: 0;
        margin: 0 auto;
      }
    }

    &:hover {
      opacity: .8;
    }

    @include breakpoint(phones) {
      margin-top: 80px;
      margin-left: auto;
      padding-left: 0;
      img {
        max-width: 30vw;
      }
    }
  }

  @include breakpoint(phones) {
    padding-top: 0;
    text-align: center;
  }

  &.visible {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .nav {
    display: inline-block;
    float: right;
    margin-top: 30px;
    font-size: .9em;

    li {
      position: relative;
      margin: 0 2em;
      padding: 0;
      display: inline-block;
    }

    a {
      text-transform: uppercase;
      color: rgba($font-light, .8);
      letter-spacing: 2px;
      padding: 1em 0;
      text-decoration: none;
      display: block;

      &:after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        background: linear-gradient(45deg, rgba(255,0,75,1), rgba(255,85,0,1));
        transition: width 0.1s ease 0s, left 0.1s ease 0s;
        width: 0;
      }

      &:hover {
        color: $font-light;
        &:after {
          width: 100%;
          left: 0;
        }
      }
    }

    @include respond('max-width: 940px') {
      display: none;
    }
  }


}

