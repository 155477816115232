//---------------------------------------------------
// HOME
//---------------------------------------------------

.home {
  color: $font-light;

  .lead-animation {
    z-index: 2;
    position: relative;

    .lead {
      opacity: 0;
      text-align: center;
      font-weight: 700 !important;
      font-size: 5.5vw;

      @include breakpoint(phones) {
        font-size: 1.5em;
      }
    }
  }

  .down {
    opacity: 0;
    bottom: 90px;
    position: absolute;
    z-index: 3;
    left: 50%;
  }

  .down span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    border: 2px solid #fff;
    border-radius: 50px;
    box-sizing: border-box;
  }

  .down span::before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sdb10 2s infinite;
    animation: sdb10 2s infinite;
    box-sizing: border-box;
  }

  .globe {
    $size: 85%;
    $margin: (100% - $size) / 2;
    position: absolute;
    height: $size;
    width: $size;
    mix-blend-mode: difference;
    z-index: 1;
    margin-top: $margin;
    margin-left: $margin;

    img {
      width: 100%;
      height: auto;
    }
  }
}

//---------------------------------------------------
// ANIMATIONS
//---------------------------------------------------

@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

//---------------------------------------------------
// VISUALS
//---------------------------------------------------

.animation-wrapper {
  width: 50%;
  padding-bottom: 50%;
  position: absolute;
  left: 25%;
  top: 50%;
  margin-top: -25%;
}

.sphere-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 580px;
  height: 580px;
  margin: -290px 0 0 -290px;

  @media (min-width: 1800px) {
    $s: 400px;
    $offset: $s / 2 * -1;
    width: $s;
    height: $s;
    margin: $offset 0 0 $offset;
  }

  @include breakpoint(phones) {
    // $s: 1500px;
    // $offset: $s / 2 * -1;
    // width: $s;
    // height: $s;
    // margin: $offset 0 0 $offset;
  }
}

@include breakpoint(phones) {
  .globe img, .sphere-animation svg {
    transform: scale(1.5);
  }
}

.sphere path {
  fill: url(#sphereGradient);
  // fill: url('../img/globle.png');
  stroke-width: 1px;
  stroke: rgba(80,80,80,.35);
  backface-visibility: hidden;
}

@media (min-width: 500px) {
  .sphere path {
    stroke-width: .4px;
  }
}

