//---------------------------------------------------
// CONTACT
//---------------------------------------------------

.contact {
  text-align: center;

  .content {
    position: relative;
    z-index: 1;
    margin-top: -10px;
  }

  h1 {
    margin-bottom: 10vh;
    color: white;
    // background: -webkit-linear-gradient(45deg, rgba(255,0,75,1), rgba(255,85,0,1));
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  .contact {
    color: rgba($font-light, .8);
    letter-spacing: 2px;
    padding: 1em 0;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    position: relative;
    font-size: 1.5em;

    &:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 1px;
      left: 50%;
      position: absolute;
      background: linear-gradient(45deg, rgba(255,0,75,1), rgba(255,85,0,1));
      transition: width 0.1s ease 0s, left 0.1s ease 0s;
      width: 0;
    }

    &:hover {
      color: $font-light;
      &:after {
        width: 100%;
        left: 0;
      }
    }
  }


  @include breakpoint(phones) {
    .content { margin: 0 5vw; }
    h1 { font-size: 2em; }
    .contact { font-size: 1.5em; text-decoration: underline; }
  }

  .js-draw {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

}

