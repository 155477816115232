* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body.no-overflow, html.no-overflow {
  min-height: 100vh;
  overflow: hidden;
  background: #131314;
  color: white;
  cursor: url('../img/cursor.png'), auto !important;
}

//---------------------------------------------------
// MISC
//---------------------------------------------------

// center imgs
img {
  margin: 0 auto;
}

#fullpage {
  opacity: 0;
  //overflow-x: hidden;
  &.show {
    opacity: 1;
  }
}

#fp-nav {
  opacity: 0;
  ul li a span {
    background: $teal;
  }
}


#landscape {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  text-align: center;
  color: white;
  z-index: 1000000000000000;
  background: #4e4e4e;
  font-size: .5em;
  h1 {
    margin-top: calc(50vh - 15px);
  }
  &.visible {
    display: block;
  }
}

label.error {
  color: #a94442;
  margin-bottom: 0;
}

.background-dark {
  background: #101010;
}

a,
a:hover,
a:visited {
  color: white;
  text-decoration: underline
}


@media (min-width: 769px) {
  .iframe-wrapper {
    margin: 0 auto;
    width: 990px;
  }
}

.wrap{
  padding-top: 20px;
  background-color: white;
  color: black;

  a,
  a:hover,
  a:visited {
    color: black;
    text-decoration: underline
  }
}

.footer {
  padding-top: 30px;
  color: white
}
