//---------------------------------------------------
// WHY
//---------------------------------------------------

.why {
  background-size: cover;
  color: $font-light;
  font-size: 1.85em;

  .content {
    // margin-top: -10vh;
    z-index: 1;
    position: relative;
  }

  .lead {
    margin-top: 4em;
    font-size: 1.1em;
    h1 {
      font-size: 2em;
    }
    p {
      font-weight: 100;
    }
  }

  .row {
    margin-bottom: 5vh;
  }

  .columns {
    h4 {
      line-height: 1.6;
    }
    p {
      font-size: .7em;
      font-weight: 100;
    }
  }

  .icon {
    float: right;
    max-width: 80px;
    margin: 0 0 1em 1em;
  }

  @include respond('max-width: 768px') {
    .content {
      margin: -10vh auto 0;
      max-width: 550px;
    }
    .icon {
      margin-bottom: 80px;
    }
  }

  @include breakpoint(phones) {
    font-size: 1.25em;
    .content {
      margin: 0 5vw;
    }

    .lead {
      margin-top: 4vh;
      //h1 { margin-bottom: .5em; }
      margin-bottom: 3vh;
      h1 { font-size: 1.5em; }
    }

    .row {
      margin-bottom: 0;
      .col-md-4 {
        margin-bottom: 2em;
        h4 {
          margin-bottom: .25em;
        }
      }
      &:nth-child(3) {
        margin-top: -.5em;
        //background: red;
      }
    }

    .icon {
      max-width: 50px;
      margin-bottom: 0;
      margin-right: 0;
      float: right;
    }
  }

  .noise-escape {
    width: calc(100% + 30px);
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 0
  }
}
