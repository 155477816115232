//------------------------------------------------------------------------------
// Responsive helpers
//------------------------------------------------------------------------------
.desktop-only {
  @include visible('desktops');
  @include hidden('tablets');
  @include hidden('phones');
}

.phones-only {
  @include hidden('desktops');
  @include hidden('tablets');
  @include visible('phones');
}

.phones-hidden {
  @include hidden('phones');
}

.small-phones-hidden {
  @include hidden('small-phones');
}
