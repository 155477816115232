/**
 * Custom blink animation based off animate.css
 */
@keyframes blink {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  80% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  90% {
    -webkit-transform: scale(1,0);
    transform: scale(1,0);
  }


  100% {
    -webkit-transform: scale(1,1);
    transform: scale(1,1);
  }
}

.blink {
  -webkit-animation-name: blink;
  animation-name: blink;
  -webkit-transform-origin: center bottom;
  transform-origin: center center;
}