// @font-face {
//   font-family: 'Font-Name';
//   src: url('../fonts/font-name.eot');
//   src: url('../fonts/font-name.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/font-name.woff') format('woff'),
//        url('../fonts/font-name.ttf') format('truetype'),
//        url('../fonts/font-name.svg#font-name') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }


//---------------------------------------------------
// FONT STYLES
//---------------------------------------------------

html {
  //font-size: 100%;
  background: #111;
}
body {
  //font: 300 1.5em/1.6;
  // font-size: 2em;
  line-height: 1.6;
  font-family: 'Red Hat Text', sans-serif;
  font-weight: 300;
  color: $font-dark;
  //transition: all .4s .3s ease-in;
}

strong {
  font-weight: 500;
}

// h1 { font-size: 3em }

h1, h2, h3, h4, h5 {
  font-weight: 500;
  margin-bottom: 1.25em;
}

.heading-uc {
  text-transform: uppercase;
  letter-spacing: .15em;
}

p {
  margin-bottom: 1.25em;
}

.weight-light {
  font-weight: 100;
}

.xxxl { font-size: 3em; }
.xxl { font-size: 2.5em; }
.xl { font-size: 2em; }
.l { font-size: 1.5em; }