//----------------------------------*\
// COLOURS
//----------------------------------*/

$background:       #121213;

$teal:             #56C5CF;  // Main Primary color */
$teal-light:       #00ebff;
$salmon:           #ff6f64;

$font-dark:        #2a3031;
$font-light:       #fff;