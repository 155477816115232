//---------------------------------------------------
// PRELOADER
//---------------------------------------------------

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../img/logo.png') center center no-repeat;
  background-size: 20vw auto;
  z-index: 999;

  @include breakpoint(phones) {
    background-size: 40vw auto;
  }
}

