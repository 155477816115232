.background-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .line {
    position: absolute;
    display: block;
    z-index: 990;
    width: 1px;
    transform: rotate(-60deg);
    transform-origin: 0% 0%;
    top: 0%;
    left: 0%;
    background: rgba(#555, .15);
  }

  .full-line {
    @extend .line;
    height: 200%;
    $m: 12vw;
    &:first-child { left: -($m * 2) }
    &:nth-child(2) { left: -$m }
    &:nth-child(4) { left: $m }
    &:nth-child(5) { left: $m *2 }

    &.deg-60 {
      right: 0;
      transform: rotate(60deg);
    }

    &.deg-180 {
    }
  }

  .small-line {
    @extend .line;
    height: 5vh;
  }
}