//------------------------------------------------------------------------------
// Responsive mixins
//------------------------------------------------------------------------------

/**
 * Set breakpoint widths. These values can be overridden in
 * a master _config.scss file
 */
$breakpoint-small-phones:   320px !default;
$breakpoint-phones:         480px !default;
$breakpoint-tablets:        768px !default;
$breakpoint-desktops:       1120px !default;

/**
 * Create a custom breakpoint:
 *   @include respond('max-height: 500px') { ... }
 */
@mixin respond($breakpoint, $media: all) {
  @media only #{$media} and ($breakpoint) {
    @content;
  }
}


/**
 * Set a breakpoint by name:
 *   @include breakpoint(phones) { ... }
 *
 * Important to note that if you're styling for phone AND small-phones
 * you need to inlucde this mixin in a large to small heirarchy so
 * your do not superceed each other:
 *   @include breakpoint(tablets) { ... }
 *   @include breakpoint(phones) { ... }
 *   @include breakpoint(small-phones) { ... }
 */
@mixin breakpoint($breakpoint, $media: all) {
  @if $breakpoint == desktops {
    @media only #{$media} and (min-width: $breakpoint-desktops) {
      @content;
    }
  }
  @else if $breakpoint == tablets {
    @media only #{$media} //and (min-width: $breakpoint-tablets) // 768
                          and (max-width: $breakpoint-desktops - 1) {
      @content;
    }
  }
  @else if $breakpoint == phones {
    @media only #{$media} //and (min-width: $breakpoint-phones)
                          and (max-width: $breakpoint-tablets - 1) {
      @content;
    }
  }
  @else if $breakpoint == small-phones {
    @media only #{$media} and (max-width: $breakpoint-phones - 1) {
      @content;
    }
  }
}

//------------------------------------------------------------------------------
// Content helpers
//------------------------------------------------------------------------------

/**
 * Hide content based on breakpoint name:
 *   @include hidden(phones);
 */
@mixin hidden($media) {
  @include breakpoint($media) {
    display: none !important;
  }
}

/**
 * Show content for a certain breakpoint:
 *   @include visible(tablets);
 */
@mixin visible($media) {
  @include breakpoint($media) {
    display: inherit !important;
  }
}