#ball {
  width: 60px;
  height: 60px;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  mix-blend-mode: exclusion;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -16px 0 0 -18px;
  pointer-events: none;
  z-index: 2;

  @include breakpoint(phones) {
    display: none;
  }
}