//---------------------------------------------------
// ABOUT
//---------------------------------------------------

.about {
  font-size: 1.85em;
  color: rgba(255, 255, 255, 1);

  p {
    font-weight: 300;
  }

  h1 {
    font-size: 3em;
    line-height: .7;
    margin-bottom: 70px;

    .spend {
      font-weight: 700;
      display: block;
    }

    .sub-text {
      display: block;
      font-size: 20px;
      display: inline-block;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      // color: rgba(255,75,75,1);
      background: -webkit-linear-gradient(45deg, rgba(255,0,75,1), rgba(255,85,0,1));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      strong {
        font-weight: bold;
      }
    }
  }

  @include breakpoint(phones) {
    font-size: 1.25em;
    h1 {
      margin-top: 30px;
      font-size: 1.5em;
      line-height: 1.2;
      margin-bottom: 40px;

      .spend { font-size: 1.5em; margin-bottom: 10px; display: block; }
      .sub-text { font-size: .7em; }
    }
    .content {
      margin: 0 5vw;
    }
  }

  // clients
  .clients {
    margin-top: 5vh;
    margin-left: calc(50% - (75vw / 2));
    max-width: 75vw;

    img {
      opacity: .25;
      max-width: 60%;
      filter: invert(100);
      -webkit-filter: invert(100);
    }

    img[src*='jet.png'] {
      max-height: 40px;
    }
    .col-md-3 {
      text-align: center;
      align-items: center;
      height: 60px;
    }

    @include breakpoint(tablets) {
      margin-top: 2vh;
      max-width: 100vw;
      margin-left: 0;

      div {
        padding: 2vh;
        img {
          max-height: 25px !important;
        }
      }
    }

    @include respond('max-height: 650px') {
      margin-right: 0;
      margin-top: -20px;

      .col-md-3 {
        height: 50px;
        padding: 0;
        opacity: 0;
      }
    }
  }

  // @include breakpoint(tablets) {
  //   .content {
  //     padding: 5vw 5vw 2vw;
  //   }
  // }

  @include breakpoint(small-phones) {
    .content {
      padding: 5vw;
    }
  }
}


